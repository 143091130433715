import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './en/translation.json'
import nl from './nl/translation.json'
import fr from './fr/translation.json'
import de from './de/translation.json'

const resources = {
  en: { translation: en },
  nl: { translation: nl },
  fr: { translation: fr },
  de: { translation: de },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    preload: resources,
    fallbackLng: 'nl',
    supportedLngs: ['en', 'nl', 'fr', 'de'],
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'navigator', 'path'],

      lookupLocalStorage: 'abelcare-locale',
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    // react-i18next options
    react: {
      useSuspense: false,
    },
  })
  .then(t => {
    if (typeof window !== 'undefined') {
      const lang =
        localStorage.getItem('abelcare-locale').split('-')[0] ||
        navigator.languages[0].split('-')[0]
      if (window.location.pathname === '/' && lang !== 'en') {
        if (Object.hasOwnProperty.call(resources, lang)) {
          localStorage.setItem('abelcare-locale', lang)
          window.location.pathname = `/${lang}`
        }
      }
    }
  })

export default i18n
