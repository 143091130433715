import React from 'react'
import i18next from './src/locale/i18next'
import { I18nextProvider } from 'react-i18next'
import { PageContextProvider } from './src/context/page-context'
import { StoreProvider } from './src/context/store-context'

export function onRenderBody({ setHeadComponents }) {
  setHeadComponents([
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js-na1.hs-scripts.com/7907515.js"
    ></script>,
  ])
}

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <StoreProvider>{element}</StoreProvider>
    </I18nextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}
