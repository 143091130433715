exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-cart-tsx": () => import("./../../../src/templates/cart.tsx" /* webpackChunkName: "component---src-templates-cart-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-faq-detail-tsx": () => import("./../../../src/templates/faq-detail.tsx" /* webpackChunkName: "component---src-templates-faq-detail-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-index-depypere-tsx": () => import("./../../../src/templates/index-depypere.tsx" /* webpackChunkName: "component---src-templates-index-depypere-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-manual-detail-tsx": () => import("./../../../src/templates/manual-detail.tsx" /* webpackChunkName: "component---src-templates-manual-detail-tsx" */),
  "component---src-templates-manuals-tsx": () => import("./../../../src/templates/manuals.tsx" /* webpackChunkName: "component---src-templates-manuals-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-product-detail-jsx": () => import("./../../../src/templates/product-detail.jsx" /* webpackChunkName: "component---src-templates-product-detail-jsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../../../src/templates/terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */)
}

